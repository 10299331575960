import React from 'react';
import { Link, graphql } from "gatsby"
import styled from "styled-components"
import Img from "gatsby-image"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { FaWhatsapp, FaFacebookF, FaTwitter, FaLinkedinIn } from "react-icons/fa"
import { IoMdMail } from "react-icons/io"

import Layout from "../components/layout"
import Facebook from '../components/social/facebook';
import Whatsapp from '../components/social/whatsapp';
import Twitter from '../components/social/twitter';
import Linkedin from '../components/social/linkedin';
import Email from '../components/social/email';
import SEO from '../components/seo';
import ValuesList from '../components/values-list';
import BackgroundImage from 'gatsby-background-image';

const ValuesTemplate = ({ data }) => {

    const options = {
        renderNode: {
            "embedded-asset-block": (node) => {

                const url = node.data.target.fields.file["en-US"].url
                const alt = node.data.target.fields.title["en-US"]

                return <img style={{ width: "100%" }} alt={alt} src={url}/>
            }
        }
    }

    return (  
        <Layout>
            <SEO 
            pageType="article"
            linkTitle={`${data.value.valuePageLinkTitle}`}
            pageDescription={data.value.valuePageLinkDescription}
            pageURL={`${data.site.siteMetadata.url}values/${data.value.slug}`}
            pageTitle={`${data.value.valuePageTitle}`}
            linkImage={`https:${data.value.valuePageBanner.file.url}`}
            />
            <TopLinkContainer>
              <TopLinkBox>
                <TopLink to="/values">About The Francis Hutcheson Institute</TopLink>
              </TopLinkBox>
            </TopLinkContainer>
            <BackgroundImage fluid={data.value.valuePageBanner.fluid}>
            <TopBanner>
                <TopWrapper>
                    <BlogDate>The Francis Hutcheson Principles</BlogDate>
                    <h1 style={{ textAlign: "center", lineHeight: "1.5", marginBottom: "0"}}><BlogTitle>{data.value.title}</BlogTitle></h1>
                    
                </TopWrapper>    
            </TopBanner>
            </BackgroundImage>
            <LowerSection>
                <BlogBody>
                    <Blog>{documentToReactComponents(data.value.valueTopSection.json, options)}</Blog>
                    <Quote>"{data.value.valueQuote.valueQuote}"</Quote>
                    <Blog>{documentToReactComponents(data.value.valueBottomSection.json, options)}</Blog>

                </BlogBody>
            </LowerSection>
            <Break>
              <div className="line"></div>
            </Break>
            <ValuesList />
        </Layout>
    );
}
 
export default ValuesTemplate;

export const pageQuery = graphql`
    query ($slug: String!) {
        value:contentfulValues(slug: {eq: $slug}) {
          title
          valuePageLinkTitle
          valuePageLinkDescription
          valuePageTitle
          valuePageBanner {
            fluid {
              ...GatsbyContentfulFluid
            }
            file{
              url
            }
          }
          valueTopSection {
            json
          }
          valueQuote {
            valueQuote
          }
          valueBottomSection {
            json
          }
        }
        site {
            siteMetadata {
                url
            }
        }
    }   
  
`

const TopLinkContainer = styled.div`
  margin-top: 80px;
  width: 100%;
  background-color: #004225;
  padding: 10px 0;
  font-family: 'Raleway', sans-serif !important;

  @media (max-width: 769px) {
    margin-top: 70px;
  }

`
const TopLinkBox = styled.div`
  max-width: 1200px;
  margin: 0 auto;
  dislpay: flex;
  padding: 0px 40px;
  justify-content: flex-start;

  @media (max-width: 769px) {
    padding: 0px 2rem !important;
  }
`

const TopLink = styled(Link)`
  display: inline-block;
  text-decoration: none;
  color: #ffffff;
  border-bottom: 2px solid rgba(255, 255, 255, 0.1);
  font-size: 12px;
  font-weight: 700;

  &:hover{
    border-bottom: 2px solid #ffffff;
    transition: all 300ms linear 0s ;
  }

`

const TopBanner = styled.div`
  background-color: rgba(0, 66, 37, 0.4);
  position: relative;
  padding-top: 140px;
  padding-bottom: 140px;
  display: flex !important;
  flex-direction: column;
  justify-content: center !important;
  align-items: center;
  @media (max-width: 450px) {
    padding-bottom: 100px;
    padding-top: 100px;
  }
`

const TopWrapper = styled.div`
    display: flex !important;
    flex-direction: column;
    justify-content: center !important;
    align-items: center;
    max-width: 850px;
    padding-left: 20px;
    padding-right: 20px;
`

const BlogDate = styled.p`
    display: block;
    font-family: 'Cormorant Garamond', serif !important;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 24px;
    font-style: italic;
    margin-bottom: 40px;
    margin-top: 10px;
`

const ShareLabel = styled.p`
    display: block;
    font-family: 'Raleway', sans-serif !important;
    color: #FFFFFF;
    font-weight: 700;
    font-size: 18px;
    margin-top: 30px;
    margin-bottom: 20px;
`

const TopSocialButtons = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`
const SocialIcon = styled.a`
  color: #FFFFFF;
  font-size: 40px;
  margin-left: 10px;
  margin-right: 10px;
  @media (max-width: 450px) {
    font-size: 9vw;
  }

  .facebook-top:hover {
    color: #C4C4C4 !important;
    transition: all 300ms linear 0s !important;
  }

  .whatsapp-top:hover {
    color: #C4C4C4;
    transition: all 300ms linear 0s ;
  }
  
  .twitter-top:hover {
    color: #C4C4C4;
    transition: all 300ms linear 0s ;
  }

  .linkedin-top:hover {
    color: #C4C4C4;
    transition: all 300ms linear 0s ;
  }

  .email-top:hover {
    color: #C4C4C4;
    transition: all 300ms linear 0s ;
  }
`

const BlogTitle = styled.span`
  color: #FFFFFF;
  text-align: center;
  font-size: 38px;
  font-weight: 900;
  display: inline;
  font-family: 'Raleway', sans-serif !important;

  @media (max-width: 450px) {
    font-size: 7vw;
  }
  
`

const LowerSection = styled.div`
  background-color: #FFFFFF;
  padding-top: 50px;
  padding-bottom: 30px;
  position: relative;
  @media (max-width: 450px) {
    padding-top: 30px;
  }
`

const BlogBody = styled.div`
  max-width: 1200px;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  font-family: 'Cormorant Garamond', serif !important;
  @media (max-width: 450px) {
      padding-left: 5px;
      padding-right: 5px;
    }
`

const FeaturedImage = styled(Img)`
  max-width: 850px;
  margin-left: auto;
  margin-right: auto;
  margin-top: -150px;
  margin-bottom: 60px;
  @media (max-width: 450px) {
        margin-bottom: 20px;
    }
`

const Blog = styled.div`
  font-size: 18px;
  font-weight: 400;
  color: black;
  line-height: 24px !important;
  padding-left: 50px;
  padding-right: 50px;
  @media (max-width: 450px) {
    padding-left: 15px;
    padding-right: 15px;
    font-size: 4.3vw;
 
  }

  blockquote {
    border-left: 5px solid #E2E2E2;
    padding: 10px 20px;
    margin: 0 0 10px 0;
  }
`

const Quote = styled.blockquote`
  border-left: 5px solid #C0C0C0;
  color: #C0C0C0;
  font-family: 'Cormorant Garamond', serif !important;
  font-style: italic;
  font-size: 24px;
  font-weight: 700;
  padding-left: 20px;
  line-height: 32px !important;

`

const ShareSection = styled.div`
  width: 100%;
  height: 400px;
  margin: 0 auto !important;
  background-color: #6ac180;
  font-family: 'Montserrat', sans-serif !important;
  display: flex;
  justify-content: center;
  align-items: center;

  @media (max-width: 768px) {
    height: auto;
  }
`

const ShareWrapper = styled.div`
  max-width: 1200px !important;
  padding: 30px 50px;
  margin; auto auto !important;
  display: grid;
  grid-auto-columns: 1fr;
  grid-column-gap: 15px;
  grid-row-gap: 15px;
  grid-template-columns: 1fr 1fr !important;
  grid-template-rows: auto;
  align-items: flex-start;

  @media (max-width: 991px) {
    grid-template-columns: 1fr !important;
  }

  @media (max-width: 450px) {
    padding: 30px 15px;
  }
`

const ShareMessage = styled.div`
  font-family: 'Montserrat', sans-serif !important;
  color: #ffffff;
  justify-self: start;
  @media (max-width: 991px) {
    text-align: center;
  }
`

const TopShareMessage = styled.h1`
  font-family: 'Montserrat', sans-serif !important;
  font-size: 24px;
  font-weight: 900;

`

const ShareSlogan = styled.p`
  font-family: 'Montserrat', sans-serif !important;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;

  @media (max-width: 450px) {
    display: none;
  } 
`

const MobileShareSlogan = styled.p`
  display: none;
  font-family: 'Montserrat', sans-serif !important;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;

  @media (max-width: 450px) {
    display: block;
  }  
`

const BottomShareMessage = styled.p`
  font-family: 'Montserrat', sans-serif !important;
  font-size: 18px;
  font-weight: 700;
  line-height: 24px;
  margin-top: 20px;
`

const BottomSocialButtons = styled.div`
    max-width: 800px !important;
    margin-left: auto;
    margin-right: auto;
    display: grid;
    grid-auto-columns: 1fr;
    grid-column-gap: 15px;
    grid-row-gap: 15px;
    grid-template-columns: 1fr 1fr !important;
    grid-template-rows: auto;
    align-items: center; !important;
    justify-content: center;
    justify-items: stretch;
    align-items: center;

    font-size: 16px;

    @media (max-width: 768px) {
        max-width: 480px !important;
        font-size: 1.8vw;
        grid-template-columns: 1fr 1fr !important;
    }

    @media (max-width: 450px) {
        width: 250px;
        font-size 3.8vw;
        grid-template-columns: 1fr !important;
    }
    
    
`

const Break = styled.div`
  width: 100%;
  background-color: #ffffff;
  padding: 0 30px;
  .line{
    max-width: 800px;
    height: 3px;
    margin: 20px auto;
    background-color: #C4C4C4;
    border-radius: 3px;
    
  }
  
`